import React, { useState } from "react";
import {
  useMediaQuery,
  useTheme,
  Typography,
  Avatar,
  Skeleton,
  Box,
} from "@mui/material";

export default function Hero({ windowHeight }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  return (
    <Box
      className="intro-container"
      sx={{
        height: windowHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div>
        {!avatarLoaded && (
          <Skeleton variant="circular" width={120} height={120} />
        )}
        <Avatar
          alt="Sergey Avatar"
          src="https://personal-site-content.s3.us-east-2.amazonaws.com/bitmoji_smiling.png"
          sx={{
            width: 120,
            height: 120,
            display: avatarLoaded ? "flex" : "none",
          }}
          onLoad={() => setAvatarLoaded(true)}
        />
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: isMd ? "row" : "column",
        }}
      >
        <Typography
          component="h2"
          variant="h2"
          align="right"
          color="textPrimary"
          mb={1.5}
          mr={2}
        >
          Hi I'm
        </Typography>
        {!imgLoaded && (
          <Skeleton variant="rectangular" width={300} height={80} />
        )}
        <img
          src="https://sergey.nyc3.digitaloceanspaces.com/sergey-masked-v2-cropped.webp"
          alt="name"
          style={{
            width: 300,
            display: imgLoaded ? "block" : "none",
          }}
          onLoad={() => setImgLoaded(true)}
        />
      </Box>
      <Typography
        component="p"
        variant="h6"
        align="center"
        color="textSecondary"
        sx={{
          marginTop: 4,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          ml: 5,
          mr: 4,
        }}
      >
        I'm a software developer that loves shipping code to improve people's
        lives
      </Typography>
    </Box>
  );
}
