import React from "react";
import { Typography, Box, useTheme, useMediaQuery, Stack } from "@mui/material";

export default function Summary({ windowHeight }) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });

  return (
    <Box
      sx={{
        backgroundColor: "#eeaba0",
        textAlign: "left",
        padding: 10,
      }}
    >
      <Box
        sx={{
          minHeight: windowHeight,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          component="h2"
          variant="h6"
          color="textSecondary"
          sx={{
            textAlign: "left",
            maxWidth: "1000px",
          }}
        >
          HELLO !
        </Typography>
        <Typography variant="h4">
          I know a few programming languages, I have an eye for design, I can
          spin up full fledged web apps and I occasionaly do sales. I enjoy
          understanding a little about a lot.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSm ? "row" : "column",
            alignItems: "flex-start",
            mt: 2,
          }}
        >
          <a
            href="https://www.linkedin.com/in/sergey-osu/"
            style={{ textDecoration: "none" }}
          >
            <Stack
              justifyContent="center"
              display="flex"
              alignItems="center"
              flexDirection="row"
            >
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{
                  mr: 2,
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                LINKEDIN
              </Typography>
            </Stack>
          </a>
          <a href="mailto:hello@sergey.fyi" style={{ textDecoration: "none" }}>
            <Stack
              justifyContent="center"
              display="flex"
              alignItems="center"
              flexDirection="row"
            >
              {/* <img
                src={email}
                className="skill-img"
                alt="profilePhoto"
                style={{ width: 16, marginRight: 5 }}
              /> */}
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{
                  mr: 2,
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                EMAIL
              </Typography>
            </Stack>
          </a>
        </Box>
      </Box>
    </Box>
  );
}
