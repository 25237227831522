import React, { useState } from "react";
import { Box } from "@mui/material";

import { useSpring, animated } from "react-spring";

import Typography from "@mui/material/Typography";

export default function SkillOverview({ img, title, descr, animationType }) {
  const [hover, setHover] = useState(false);

  const shakeAnimation = useSpring({
    from: { x: 0, rotate: 0 },
    to: {
      x: hover ? 5 : 0,
      rotate: hover ? 2 : 0,
    },
    config: { tension: 100, friction: 5 },
  });

  const rotateAnimation = useSpring({
    rotate: hover ? -6 : 10,
    config: { tension: 100, friction: 5 },
  });

  const { scale } = useSpring({
    scale: hover ? 1.15 : 1,
  });

  let transform = null;
  if (animationType === "rotate") {
    transform = rotateAnimation.rotate.to((r) => `rotate(${r}deg)`);
  } else if (animationType === "scale") {
    transform = scale.to((s) => `scale(${s})`);
  }

  return (
    <Box
      sx={{
        width: "200px",
        height: "200px",
        display: "flex",
        marginTop: 10,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <animated.img
        src={img}
        className="skill-img"
        alt="profilePhoto"
        style={{
          width: 40,
          marginBottom: 10,
          ...(animationType === "shake" && shakeAnimation),
          transform: transform,
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
      <Typography gutterBottom variant="h5" component="h2">
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        {descr}
      </Typography>
    </Box>
  );
}
