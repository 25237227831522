import React from "react";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@mui/material";

export default function ProjectCard({ img, descr, title, yearText, img_href }) {
  return (
    <Card
      sx={{
        maxWidth: 345,
        marginBottom: 30,
        margin: "auto",
        marginTop: 15,
        borderRadius: 2,
        position: "relative",
        transform: "scale(1)",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "scale(1.01)", // scale up on hover
          boxShadow: "0 10px 20px rgba(0,0,0,0.1), 0 6px 6px rgba(0,0,0,0.15)",
        },
      }}
    >
      <CardActionArea>
        <a href={img_href}>
          <CardMedia
            sx={{
              height: 140,
              opacity: 0.75,
            }}
            image={img}
            title="Project"
          />
        </a>
        <CardContent>
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
          <Typography gutterBottom color="textSecondary" variant="body2">
            {yearText}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {descr}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
