import React from "react";
import { Grid, Typography, Link } from "@mui/material";
import ProjectCard from "../Components/ProjectCard";
import SkillOverview from "../Components/SkillOverview";
import { Box } from "@mui/material";

import Hero from "../Components/Hero";
import Summary from "../Components/Summary";

import signature from "../Assets/signature.png";
import browser from "../Assets/browser-duotone.svg";
import flask from "../Assets/flask-duotone.svg";
import starfighter from "../Assets/starfighter-duotone.svg";

const projectContent = [
  {
    title: "Three Sigma",
    yearText: "2022",
    img: "https://sergey-filimonov.nyc3.digitaloceanspaces.com/personal-site/three-sigma-ai-2.webp",
    descr: `Semantic search as an API. Consumer product among the top plugins on the ChatGPT plugin store.`,
    img_href: "https://www.threesigma.ai/",
    buttons: [],
  },
  {
    title: "Powdamap",
    yearText: "2019",
    img: "https://personal-site-content.s3.us-east-2.amazonaws.com/powdamap_screenshot_minified.png",
    descr: `An interactive map displaying snowfall 
    forecasts for every ski resort in Colorado. Geared towards powderhounds.`,
    img_href: "https://www.powdamap.com/",
    buttons: [],
  },
  {
    title: "DialFaster",
    yearText: "2019",
    img: "https://personal-site-content.s3.us-east-2.amazonaws.com/dialfaster-header.png",
    descr: `A chrome extension designed to reduce post-call processing time in the mortgage industry.`,
    img_href:
      "https://chrome.google.com/webstore/detail/dial-faster/jhabkeojkdhjbjkpjahnbekcpaebhdkf",
    buttons: [],
  },
  {
    title: "Katanagi",
    yearText: "2020",
    img: "https://album-art-posters.s3.amazonaws.com/poster_on_wall.png",
    descr: `A tool to help create a custom poster with all of your favorite albums.`,
    img_href: "https://www.katanagi.com/create-poster",
    buttons: [],
  },
  {
    title: "Booking Anchor",
    yearText: "2021",
    img: "https://personal-site-content.s3.us-east-2.amazonaws.com/booking-anchor-highlight.png",
    descr: `A platform to help marinas manage their business and accept online booking.`,
    img_href: "https://www.bookinganchor.com/",
    buttons: [],
  },
  {
    title: "MulchJobs",
    yearText: "2019",
    img: "https://diploma-mulching.s3.us-east-2.amazonaws.com/daily_preview_screemshot2.png",
    descr: `Helps landscapers track their profits on a per job basis.`,
    img_href: "https://www.mulchjobs.com/",
    buttons: [
      // { title: "visit", href: "https://www.mulchjobs.com/" },
      // { title: "about", href: "/blog/powdamap" },
    ],
  },
  {
    title: "Freedom Radon",
    yearText: "2017",
    img: "https://personal-site-content.s3.us-east-2.amazonaws.com/trucks.jpg",
    descr: `Full serivce radon mitigation service.`,
    buttons: [],
  },
  {
    title: "Diploma Mulching",
    yearText: "2015",
    img: "https://personal-site-content.s3.us-east-2.amazonaws.com/diploma_everybody (1).jpg",
    descr: `A landscaping company founded with the goal of helping pay for student's college tuition. Business acquired in 2021.`,
    buttons: [],
  },
];

const skillContent = [
  {
    title: "Data Science",
    img: flask,
    animationType: "shake",
    descr: `Designing models all the way from ideation to concrete deliverables.`,
  },
  {
    title: "Web Development",
    img: browser,
    animationType: "scale",
    descr: `Full stack web development with a focus on React and Python.`,
  },
  {
    title: "Entrepreneurship",
    img: starfighter,
    animationType: "rotate",
    descr: `Started, ran and sold multiple companies. The best way to predict the future is to create it.`,
  },
];

export default function HomePage() {
  const windowHeight = window.innerHeight * 0.95;

  return (
    <div className="landing-page">
      <Hero windowHeight={windowHeight} />
      <Summary windowHeight={windowHeight} />
      <Box sx={{ mt: 20 }}>
        <Typography
          component="h3"
          variant="h3"
          color="textPrimary"
          align="center"
          sx={{ mb: 6 }}
        >
          What I do
        </Typography>
        <Grid container justify="center">
          {skillContent.map((value) => (
            <Grid key={value["title"]} item xs={12} md={4}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <SkillOverview
                  img={value["img"]}
                  title={value["title"]}
                  descr={value["descr"]}
                  animationType={value["animationType"]}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Grid container justify="center">
        {projectContent.map((value) => (
          <Grid key={value["title"]} item xs={12} sm={6} md={4}>
            <ProjectCard
              img={value["img"]}
              img_href={value["img_href"]}
              title={value["title"]}
              yearText={value["yearText"]}
              descr={value["descr"]}
              buttons={value["buttons"]}
            />
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          background: "black",
          alignText: "center",
          paddingTop: 8,
          paddingBottom: 8,
          mt: 20,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
          <Typography variant="h4" sx={{ color: "white", mt: -0.2 }}>
            Lets build great things together
          </Typography>
        </Box>
        <Link href="mailto:hello@sergey.fyi" underline="none">
          <Typography variant="body1" style={{ color: "white" }}>
            hello@sergey.fyi
          </Typography>
        </Link>
        <img
          src={signature}
          className="signature"
          alt="signature"
          style={{ width: 250 }}
        />
      </Box>
    </div>
  );
}
